import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private storage: Storage, private jwt: JwtHelperService) {
  }

  async isUser() {
    return await this.getUserRole() === Role.USER;
  }

  storeToken(token) {
    return this.storage.set('token', token);
  }

  storeDeviceAccessToken(token) {
    return this.storage.set('device_access_token', token);
  }

  getToken() {
    return this.storage.get('token');
  }

  getDeviceAccessToken() {
    return this.storage.get('device_access_token');
  }

  deleteToken() {
    return this.storage.remove('token');
  }

  deleteDeviceAccessToken() {
    return this.storage.remove('device_access_token');
  }

  isTokenExpired(accessToken) {
    return this.jwt.isTokenExpired(accessToken);
  }

  async isLoggedIn() {
    const token = await this.getToken();

    if (!token || this.isTokenExpired(token)) return false;

    return true;
  }

  async getLoggedUser() {
    const token = await this.getToken();

    if (!token) return null;

    const user = this.jwt.decodeToken(token);

    return user;
  }

  async getUserRole(): Promise<string | null> {
    const user = await this.getLoggedUser();
    if (!user) return null;

    return user.role;
  }
}
