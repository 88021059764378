import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { combineLatest, from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMapTo, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return combineLatest([from(this.authService.getToken()), from(this.authService.getDeviceAccessToken())])
    return from(this.authService.getToken())
      .pipe(
        // map(([a, b]) => a || b),
        switchMap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: ['JWT', token].join(' ')
            }
          });
          return next.handle(request)
            .pipe(catchError(err => {
              if ([401, 403].includes(err.status)) {
                this.router.navigate(['/login'], { replaceUrl: true });
                return from(this.authService.deleteToken()).pipe(mergeMapTo(throwError(err)));
              }
              return throwError(err);
            }));
        })
      );
  }
}
