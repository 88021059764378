import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {Role} from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RootGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.authService.getLoggedUser();
    const isExpired = await this.authService.isLoggedIn();

    if (!user || !isExpired) {
      this.router.navigate(['/login'], { replaceUrl: true });
      return false;
    }

    if (user.role === Role.ADMIN) {
      this.router.navigate(['/admin'], { replaceUrl: true });
      return false;
    }

    return true;
  }
}

