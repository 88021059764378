import {
  Component,
  ViewChild
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss']
})
export class SelectDropdownComponent implements ICellEditorAngularComp {
  @ViewChild('selected') fields;
  params: any;
  options: any[];
  cellWidth: string;
  values: number[];
  multiple: boolean;

  // Control how to display and compare complex objects
  valueFormatter: (value) => string = (value => value);
  valueComparer: (value) => any = (value => value);

  constructor(public modalController: ModalController) {
  }

  agInit(params: any): void {
    this.multiple = params.multiple || false;
    this.params = params;
    this.options = params.options;

    this.valueFormatter = params.valueFormatter || this.valueFormatter;
    this.valueComparer = params.valueComparer || this.valueComparer;

    this.values = Array.isArray(params.value) ? params.value.map(v => this.valueComparer(v)) : this.valueComparer(params.value);

    this.cellWidth = params.column.actualWidth + 'px';
  }

  private firstVal() {
    return this.values[0];
  }

  compare(value) {
    return this.valueComparer(value);
  }

  getValue(): any {
    if (!Array.isArray(this.params.value)) {
      return this.options.find(opt => this.values === this.valueComparer(opt));
    }
    return this.options.filter(opt => this.values.includes(this.valueComparer(opt)));
  }
}
