import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CurrentRowService } from 'src/app/shared/services/edit-field.service';

@Component({
  selector: 'app-row-edit',
  templateUrl: './row-edit.component.html',
  styleUrls: ['./row-edit.component.scss']
})
export class RowEditComponent implements ICellRendererAngularComp {
  params: any;
  isNew: any;
  previousData: any;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(private currentRow: CurrentRowService) {
    this.isNew = true;
  }

  invokeParentMethod(data) {
    this.params.context.onUpdate(data);
  }

  refresh(): boolean {
    return false;
  }

  onEditClick() {
    const index = this.params.node.rowIndex;
    this.params.cancelOtherRowEditors(index);
    this.params.columnApi.autoSizeAllColumns();
    this.isNew = false;
    this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
    const cols = this.params.columnApi.getAllGridColumns();
    let firstCol = {
      colId: ''
    };
    if (cols) firstCol = cols[0];
    const rowIndex = this.params.node.rowIndex;
    const rowId = this.params.node.id;
    this.currentRow.setValue(rowId);
    this.params.api.setFocusedCell(rowIndex, firstCol.colId);
    this.params.api.startEditingCell({
      rowIndex,
      colKey: cols[cols.length - 1].colDef.field
    });
  }

  onUpdateClick() {
    this.isNew = true;
    const obj: any = {};
    obj.type = 'update';
    this.params.api.stopEditing();
    this.currentRow.setValue(null);
    obj.selectedData = [this.params.data];
    this.params.columnApi.autoSizeAllColumns();
    // update logic
    this.invokeParentMethod({ data: this.params.data, prev: this.previousData });
  }

  onCancelClick() {
    this.isNew = true;
    this.currentRow.setValue(null);
    // this.params.api.applyTransaction({ update: [Object.assign(this.params.data, this.previousData)] });
    this.params.node.setData(this.previousData);
    this.params.api.stopEditing(true);
    this.params.api.deselectAll();
  }
}
