import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    const isLoggedIn = await this.authService.isLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['/login'], { replaceUrl: true });
      return false;
    }

    const user = await this.authService.getLoggedUser();
    if (!user) return false;

    const { role } = next.data;

    if (!role.includes(user.role)) {
      this.router.navigate(['/not-authorized']);
      return false;
    }

    return true;
  }
}
