import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  language = new BehaviorSubject('en');

  constructor(private storage: Storage) {
    this.initiateLang();
  }

  async initiateLang() {
    const lang = await this.getStorageLang();
    if (lang) {
      this.language.next(lang);
    }
  }

  async changeLang(lang: string) {
    this.language.next(lang);
    await this.setLang(lang);
  }

  getStorageLang() {
    return this.storage.get('lang');
  }

  setLang(lang: string) {
    return this.storage.set('lang', lang);
  }
}
