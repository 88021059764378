// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:3000/api/v1',
  mapboxApiKey: 'pk.eyJ1IjoiaWtvdmFjIiwiYSI6ImNraGNhdHJhYjAwamUycm9sOHUzamxpYWYifQ.DAoRfdKgRdG1auZu5wTXFA',
  weatherApiKey: '796e21beb898faddbd00c80187f7c623',
  weatherURLPrepend: 'https://cors-anywhere.herokuapp.com/',
  weatherURL: 'https://api.openweathermap.org/data/2.5/onecall?lat=',
  weatherExclusion: 'minutely,hourly,daily',
  weatherUnits: 'metric',
  firebase: {
    apiKey: 'AIzaSyDM315egy-wBXVIP81x8RRe5c_7DbBxSg8',
    authDomain: 'tetramax-dev.firebaseapp.com',
    projectId: 'tetramax-dev',
    storageBucket: 'tetramax-dev.appspot.com',
    messagingSenderId: '675676845808',
    appId: '1:675676845808:web:1071a0fd33063647647435'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
