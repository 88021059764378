import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { RootGuard } from './shared/guards/root.guard';
import { Role } from './shared/models/role';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/root/root.module').then(m => m.RootPageModule),
    canActivate: [RootGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
    canActivate: [AuthGuard],
    data: {
      role: [Role.ADMIN]
    }
  },
  {
    path: 'tv',
    loadChildren: () => import('./pages/device_dashboard/dashboard.module').then(m => m.DashboardPageModule),
  },
  {
    path: 'not-authorized',
    loadChildren: () => import('./pages/not-authorized/not-authorized.module').then(m => m.NotAuthorizedPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
