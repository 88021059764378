import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentRowService {
  private rowId = new BehaviorSubject(null);
  currentRow = this.rowId.asObservable();

  setValue(id: number) {
    this.rowId.next(id);
  }
}
